@font-face {
  font-family: "CustomFont";
  src: url("./S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CustomFont";
  src: url("./S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "CustomFont";
  src: url("./S6uyw4BMUTPHjx4wXiWtFCc.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
